
import { defineComponent, ref, reactive, computed, toRefs, nextTick } from 'vue';
import { PlusOutlined } from '@ant-design/icons-vue';
import { GridContent } from '@/components';
import { useStore } from 'vuex';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { queryProjectNotice } from '@/api/dashboard/workplace';

import Applications from './components/applications.vue';
import Articles from './components/articles.vue';
import Projects from './components/projects.vue';

interface TagType {
  key: string;
  label: string;
}

export default defineComponent({
  name: 'AccountCenter',
  setup() {
    const store = useStore();
    const tagInput = ref();
    const state = reactive({
      newTags: [] as TagType[],

      tagInputVisible: false,
      tagInputValue: '',

      tabListNoTitle: [
        {
          key: 'article',
          tab: '文章(8)',
        },
        {
          key: 'app',
          tab: '应用(8)',
        },
        {
          key: 'project',
          tab: '项目(8)',
        },
      ],
      titleActiveKey: 'app',
    });
    const { context: projects } = useFetchData(() => {
      return queryProjectNotice().then(res => {
        return {
          data: res,
          total: 100,
        };
      });
    });

    const handleTabChange = (key: string) => {
      state.titleActiveKey = key;
    };
    const handleTagClose = (removeTag: string) => {
      const newTags = state.newTags.filter(tag => tag.label !== removeTag);
      state.newTags = newTags;
    };
    const showTagInput = () => {
      state.tagInputVisible = true;
      nextTick(() => {
        tagInput.value.focus();
      });
    };
    const handleInputChange = (e: Event) => {
      state.tagInputValue = (e.target as HTMLInputElement).value;
    };
    const handleTagInputConfirm = () => {
      const inputValue = state.tagInputValue;
      const oldTags: string[] = state.newTags.map(tag => tag.label);
      let tags = state.newTags;
      if (inputValue && !oldTags.includes(inputValue)) {
        tags = [
          ...tags,
          {
            key: '-1',
            label: inputValue,
          },
        ];
      }
      state.newTags = tags;
      state.tagInputVisible = false;
      state.tagInputValue = '';
    };

    return {
      currentUser: computed(() => store.getters['user/currentUser']),
      tagInput,
      projects,
      ...toRefs(state),

      // func
      handleTabChange,
      handleTagClose,
      handleInputChange,
      handleTagInputConfirm,
      showTagInput,
    };
  },
  components: {
    GridContent,
    Applications,
    Articles,
    Projects,

    PlusOutlined,
  },
});
